import axios from 'axios';
import { getBaseUrl } from '../components/BaseComponent';
import { showLog } from '../models/logModel';
import * as DataManager from '../repository/DataManager';
import { getHeaders } from '../utils/AppUtils';
import { isJsonObjectEmpty, parseStringToJson } from '../utils/JsonUtils';
import { CategoryKeys } from '../utils/StringKeys';
import SharedPreferenceHelper, { SP_CREDENTIALS, SP_CUSTOM_TILES_DATA } from './SharedPreferenceHelper';
import Events from '../router/events';

export const fetchCustomTilesData = async () => {
    let userCredentials = await DataManager.getJson(SP_CREDENTIALS);
    if (!userCredentials || !userCredentials.authToken)
        return;
    var header = getHeaders();
    header['authorization'] = userCredentials.authToken;
    header['api_key'] = userCredentials.api_key;
    let appUserId = userCredentials.appUserId;
    try {
        let url = getBaseUrl();
        url = url + 'employee-wellness-app?appUserId=' + appUserId;
        showLog('fetchCustomTilesData url: ' + url);
        showLog('fetchCustomTilesData header: ' + JSON.stringify(header));
        const response = await axios.get(url, {
            headers: header,
        });
        showLog('fetchCustomTilesData response: ' + JSON.stringify(response));
        if (response && response.status == 200 && response.data.success) {
            let tilesArray = response.data.data.employeeWellnessApps;
            showLog('fetchCustomTilesData tilesArray: ' + JSON.stringify(tilesArray));
            storeCustomTilesData(tilesArray);
            return;
        } else {
            showLog('fetchCustomTilesData Failure response: ' + JSON.stringify(response));
        }
        return;
    } catch (e) {
        showLog(e);
        return;
    }
}

export const getSafetyCustomTiles = async () => {
    let jsonObj = await getCustomTilesData(CategoryKeys.SAFETY_CATEGORY);
    showLog('getSafetyCustomTiles jsonObj: ' + JSON.stringify(jsonObj));
    return jsonObj;
}

export const getWellBeingCustomTiles = async () => {
    let jsonObj = await getCustomTilesData(CategoryKeys.WELLBEING_CATEGORY);
    return jsonObj;
}

export const getHealthCustomTiles = async () => {
    let jsonObj = await getCustomTilesData(CategoryKeys.HEALTH_CATEGORY);
    return jsonObj;
}

export const getWealthCustomTiles = async () => {
    let jsonObj = await getCustomTilesData(CategoryKeys.WEALTH_CATEGORY);
    return jsonObj;
}

export const getEngagementCustomTiles = async () => {
    let jsonObj = await getCustomTilesData(CategoryKeys.ENGAGEMENT_CATEGORY);
    return jsonObj;
}

export const getCultureCustomTiles = async () => {
    let jsonObj = await getCustomTilesData(CategoryKeys.CULTURE_CATEGORY);
    return jsonObj;
}

export const getBenefitsCustomTiles = async () => {
    let jsonObj = await getCustomTilesData(CategoryKeys.BENEFITS_CATEGORY);
    return jsonObj;
}

const storeCustomTilesData = (tilesArray) => {

    let storedTilesData = {};
    if (tilesArray && tilesArray.length == 0) {
    } else {
        tilesArray.map(element => {
            let newElement = {
                label: element.title,
                labelOtherLanguage: element.titleSecondary,
                id: element.recordId,
                icon: element.image,
                url: element.url,
                type: element.category,
                inAppBrowserType: element.inAppBrowserType,
            };
            if (storedTilesData[element.category]) {
                storedTilesData[element.category].push(newElement);
            } else {
                storedTilesData[element.category] = [];
                storedTilesData[element.category].push(newElement);
            }
        });
    }

    showLog('storeCustomTilesData: success');
    SharedPreferenceHelper.setUnsecured(
        SP_CUSTOM_TILES_DATA,
        JSON.stringify(storedTilesData),
    );
    Events.publish(
        'UPDATE_BOTTOM_TAB_BAR',
        new Date().getTime() + ''
    );
    Events.publish(
        'UPDATE_DRAWER_MENU_CUSTOM_TILES_COUNT',
        new Date().getTime() + ''
    );
}
const isCustomTilesDataExist = async () => {
    let storedTilesData = await SharedPreferenceHelper.getUnsecured(SP_CUSTOM_TILES_DATA);
    if (!storedTilesData)
        return;

    let jsonObj = parseStringToJson(storedTilesData);
    if (isJsonObjectEmpty(jsonObj))
        return;

    return jsonObj;
}

const getCustomTilesData = async (key) => {
    let jsonObj = await isCustomTilesDataExist();
    showLog('getCustomTilesData jsonObj: ' + JSON.stringify(jsonObj));
    if (!jsonObj)
        return;
    if (!jsonObj[key] || jsonObj[key].length == 0)
        return;

    return jsonObj[key];
}
