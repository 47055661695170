//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { FirebaseRecaptchaVerifierModal, FirebaseRecaptchaBanner } from 'expo-firebase-recaptcha';
import { getFirebaseConfig } from '../../../utils/AppUtils';
import { verticalScale } from '../../../styles/Scaling';
import { textStyles } from '../../../styles/textFontStyles';
import { colors } from '../../../styles/colors';

// create a component
const CaptchaModal = (props) => {
    if (props.showCaptcha){
        return (
            <View style={{
                paddingHorizontal: 20,
                flex: 1,
                width: '100%',
           //     height : '100%',
                alignItems: 'center',
                marginTop: 0,
            //    paddingBottom: props.showCaptcha ? verticalScale(120) : 0,
            }}>
                 {/* {(props.showReCaptchaWarning) && (
                    <Text
                    style={[
                        textStyles.IBMPlexSansRegular,
                        {
                        color: colors.textcolor,
                        textAlign: 'center',
                        fontSize: verticalScale(14),
                        paddingHorizontal: verticalScale(100),
                        paddingVertical: 8,
                        backgroundColor: colors.warningBoxBg,
                        borderRadius: 6,
                        marginBottom: verticalScale(15),
                     //   marginTop: verticalScale(15)
                        },
                    ]}
                     >We require additional veriifcation, your login appears suspicious or unusual. Please fill out the Captcha</Text>
                )} */}

                <FirebaseRecaptchaVerifierModal
                    ref={props.recaptchaVerifier}
                    firebaseConfig={getFirebaseConfig()}
                    onVerify={() => { }}
                   attemptInvisibleVerification={props.attemptInvisibleVerification}
                />
            </View>
        );
    } return <View></View>

};



//make this component available to the app
export default CaptchaModal;
