import AsyncStorage from "@react-native-async-storage/async-storage"
import { showLog } from "../../../models/logModel";
import { isJsonObjectEmpty } from "../../../utils/JsonUtils";

const COOKIES_STORE = 'COOKIES_STORE';

export const saveCookies = async (domain, cookieString) => {

    showLog('COOKIES_STORE saveCookies value: ' + cookieString);
    let value = await AsyncStorage.getItem(COOKIES_STORE);

    let obj = {};
    if (value) {
        obj = JSON.parse(value);

    } else {
        obj = {};
    }
    obj[domain] = cookieString;
    AsyncStorage.setItem(COOKIES_STORE, JSON.stringify(obj));
}

export const getCookies = async (domain) => {
    let value = await AsyncStorage.getItem(COOKIES_STORE);
    showLog('COOKIES_STORE getCookies value: ' + value);
    if (value) {
        let obj = JSON.parse(value);

        return obj[domain];
    }
    return;
}

export const getDomainFromUrl = (url) => {
    if (url) {
        // run against regex
        const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
        // extract hostname (will be null if no match is found)
        return matches && matches[1];
    }
    return;
}
