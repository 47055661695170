import React, { useEffect, useState } from 'react';
import { Modal, StyleSheet, TouchableOpacity, View, Text, Platform } from 'react-native';
import { getGlobalOrganization, getGlobalOrganizationID } from '../models/usersModel';
import DeviceInfo, { getBundleId } from 'react-native-device-info';

import I18n from '../utils/I18n';
import { getDrawerMenuFotterIcon } from '../models/SvgIconCollectionModel';
import { isMobile } from '../utils/AppUtils';

const DrawerMenuFooter = () => {

    const [orgName, setOrgName] = useState('');
    const [orgId, setOrgId] = useState('');
    const [appVersion, setAppVersion] = useState('');

    useEffect(() => {
        setData();
    }, []);

    const setData = async () => {
        let org = await getGlobalOrganization();
        let orgId = await getGlobalOrganizationID();
        let appVer = DeviceInfo.getVersion();
        setOrgName(org);
        setOrgId(orgId);
        setAppVersion(appVer);
    }

    const getDrawerFooterIcon = () => {
        return (
            getDrawerMenuFotterIcon(40)
        );
    }


    return (
        <View style={{
            paddingHorizontal: 22,
            // paddingTop: 20,
            paddingBottom: 20,
        }}>

            <Text style={styles.titleTextStyle}>
                {I18n.t('menuItemLabel.' + 'menu_organization')}
            </Text>
            <Text style={[styles.subTitleTextStyle, { marginTop: 7 }]}>
                {orgName}
            </Text>
            <Text style={[styles.titleTextStyle, {
                fontFamily: 'Inter-Medium',
                fontSize: 11,
                color: 'rgba(255, 255, 255, 0.5)',
                marginTop: 1
            }]}>
                {I18n.t('menuItemLabel.' + 'menu_org_id') + ' ' + orgId}
            </Text>
            <View style={{
                flexDirection: 'row',
                height: 100,
                width: '100%',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                paddingBottom: 10
            }}>

                {/* <View style={{ height: 20, width: 20 }} /> */}
                {/* <WWLogoSvg /> */}
                {getDrawerFooterIcon()}
                {isMobile() ? (
                    <Text style={[styles.titleTextStyle, {
                        fontFamily: 'Inter-Regular',
                        fontSize: 10,
                        color: 'rgba(255, 255, 255, 0.5)',
                    }]}>
                        {'Version ' + appVersion}
                    </Text>
                ) : null}

            </View>

        </View>
    );
}
const styles = StyleSheet.create({
    titleTextStyle: {
        fontFamily:
            Platform.OS === 'web'
                ? 'Inter-SemiBold'
                : Platform.OS === 'android'
                    ? 'Inter-Bold'
                    : 'Inter-Bold',
        fontWeight: '700',
        fontSize: 10,
        color: 'rgba(255, 255, 255, 0.7)'
    },
    subTitleTextStyle: {
        fontFamily:
            Platform.OS === 'web'
                ? 'Inter-SemiBold'
                : Platform.OS === 'android'
                    ? 'Inter-SemiBold'
                    : 'Inter-SemiBold',
        fontWeight: '600',
        fontSize: 18,
        color: '#fff'
    },
});
export default DrawerMenuFooter;