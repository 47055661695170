import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, Text, ActivityIndicator } from 'react-native';
import Events from '../router/events';
import { EVENT_PROFILE_IMAGE_SELECTED, EVENT_PROFILE_IMAGE_UPLOADING, EVENT_PROFILE_IMAGE_UPLOADING_FINISHED, EVENT_SHOW_IMAGE_SELECTION_POP_UP_MODEL } from './BottomPopUpImageSelection';
import ImageView from './atom/ImageView';
import { showLog } from '../models/logModel';
import { SP_CREDENTIALS, SP_PROFILE_IMAGE } from '../repository/SharedPreferenceHelper';
import * as DataManager from '../repository/DataManager';
import { getHeaders, isMobile, isWeb } from '../utils/AppUtils';
import axios from 'axios';
import { getBaseUrl } from './BaseComponent';
import { getStoredImage, saveImage, uploadImage, uploadProfileImage } from '../models/ImageFileManager';
import { colors } from '../styles/colors';
import { getProfilePicHolderIcon } from '../models/SvgIconCollectionModel';
import { isValidImageFileFormat } from '../models/VaccineModel';
export const EVENT_PROFILE_IMAGE_REMOVE = 'EVENT_PROFILE_IMAGE_REMOVE';

const ProfilePicHolder = (props) => {

    const [avtar, setAvtar] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        showLog('TORY useEffect');
        getProfileImage();
    }, []);

    useEffect(() => {
        let eventHandle = Events.subscribe(
            EVENT_PROFILE_IMAGE_UPLOADING,
            data => {
                showLog('TORY ProfilePicHolder useEffect EVENT_PROFILE_IMAGE_UPLOADING');
                setLoading(true);
                saveImage('profile_pic', null, SP_PROFILE_IMAGE);
            },
        );
        return () => eventHandle.remove();
    }, []);

    useEffect(() => {
        let eventHandle = Events.subscribe(
            EVENT_PROFILE_IMAGE_UPLOADING_FINISHED,
            data => {
                showLog('TORY ProfilePicHolder useEffect EVENT_PROFILE_IMAGE_UPLOADING_FINISHED');
                getProfileImage();
            },
        );
        return () => eventHandle.remove();
    }, []);

    useEffect(() => {
        let eventHandle = Events.subscribe(
            EVENT_PROFILE_IMAGE_REMOVE,
            data => {
                removeProfileImage();
            },
        );
        return () => eventHandle.remove();
    }, []);

    const showBottomPopUpDialog = () => {
        showLog('showBottomPopUpDialog value: ');
        let showDeleteImageOption = (avtar != null);
        Events.publish(EVENT_SHOW_IMAGE_SELECTION_POP_UP_MODEL, showDeleteImageOption);

    }

    const getProfileImage = async () => {
        try {
            showLog('TORY ProfilePicHolder getProfileImage');
            const storedImage = await getStoredImage('profile_pic', SP_PROFILE_IMAGE);
            showLog('TORY ProfilePicHolder getProfileImage storedImage: ' + JSON.stringify(storedImage));
            if (storedImage) {
                setLoading(false);
                setAvtar(storedImage);
            } else {
                setLoading(true);
                showLog('TORY ProfilePicHolder getProfileImage downloading from server');
                const userCredentials = await DataManager.getJson(SP_CREDENTIALS);
                if (userCredentials) {
                    var header = getHeaders();
                    header['authorization'] = userCredentials.authToken;
                    header['api_key'] = userCredentials.api_key;
                    let url = getBaseUrl() + 'user/get-profile-pic?appUserId=' + userCredentials.appUserId;
                    const response = await axios.get(url, {
                        headers: header,
                    });
                    showLog('TORY ProfilePicHolder getProfileImage downloading from server: ' + JSON.stringify(response));
                    if (response && response.data.success) {
                        let base64File = response.data.data.file;
                        if (base64File) {
                            let source = { uri: base64File, fileName: '', isBase64: true };
                            showLog('TORY ProfilePicHolder getProfileImage saving in local : ' + JSON.stringify(source));
                            saveImage('profile_pic', source, SP_PROFILE_IMAGE);
                            setAvtar(source);
                        } else {
                            saveImage('profile_pic', null, SP_PROFILE_IMAGE);
                            setAvtar(null);
                        }
                    }
                }
                setLoading(false);
            }
            showLog('TORY ProfilePicHolder getProfileImage completed');
        } catch (ex) {
            showLog('TORY error ' + ex);
            showLog(ex);
        }
    }

    const removeProfileImage = async () => {
        try {
            const user = await DataManager.getJson(SP_CREDENTIALS);
            let header = getHeaders();

            header['authorization'] = user.authToken;
            header['content-type'] = 'multipart/form-data';
            let postFormData = new FormData();
            postFormData.append('appUserId', user.appUserId);
            let response;
            try {
                let url = getBaseUrl() + 'user/delete-profile-pic';
                response = await axios.post(url, postFormData, {
                    headers: header,
                });
                showLog('removeProfileImage response : ' + JSON.stringify(response));
            } catch (et) {
                showLog(et);
            }
            if (response && response.data.success) {
                let data = response.data;
                showLog('removeProfileImage response.data : ' + JSON.stringify(data));
                showLog('removeProfileImage sucess');
                saveImage('profile_pic', null, SP_PROFILE_IMAGE);
                setAvtar(null);
            } else {
                showLog('removeProfileImage Failed: ');
                showLog('removeProfileImage Failed');
            }
        } catch (ex) {
            showLog('removeProfileImage error ' + ex);
            showLog(ex);
        }
    }

    const getContentView = () => {
        if (loading) {
            return (
                <ActivityIndicator size="small" color={colors.primary_blue} />
            );
        }
        if (avtar) {
            return (
                <ImageView
                    style={{
                        height: props.size,
                        width: props.size,
                        borderRadius: props.size * 0.15,
                    }}
                    source={
                        avtar && avtar.uri && {
                            uri: avtar.isBase64 ? `data:image/jpeg;base64,${avtar.uri}` : avtar.uri,
                        }
                    }
                />
            );
        } else {
            return (
                getProfilePicHolderIcon(props.size / 2)
            );
        }
    }

    const getViewForMobile = () => {
        return (
            <View>
                <TouchableOpacity
                    activeOpacity={0.8}
                    style={{
                        height: props.size,
                        width: props.size,
                        backgroundColor: '#FFFFFF',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: props.size * 0.15,
                    }}
                    onPress={() => {
                        showBottomPopUpDialog();
                    }}
                >
                    {getContentView()}
                </TouchableOpacity>
            </View>
        )

    }

    const getViewForWeb = () => {
        return (
            <View style={{}}>

                <View style={{
                    opacity: 0,
                    width: 50,
                    position: 'absolute',

                }}>
                    <input
                        type='file'
                        accept="image/png, image/jpeg, image/jpg, image/gif"
                        id={'profile_pic_selection'}
                        onClick={() => { }}
                        onChange={(e) => { onWebBasedImageSelected(e) }} />
                </View>
                <label
                    style={{ width: props.size }}
                    for={'profile_pic_selection'}>
                    <TouchableOpacity
                        activeOpacity={0.8}
                        style={{
                            height: props.size,
                            width: props.size,
                            backgroundColor: '#FFFFFF',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: props.size * 0.15,
                        }}
                        onPress={() => {
                            // showBottomPopUpDialog();
                        }}
                    >

                        {getContentView()}
                    </TouchableOpacity>
                </label>
            </View>
        )

    }

    const onWebBasedImageSelected = (webFile) => {
        if (isWeb() && webFile) {
            let file = webFile.target.files[0];
            let isValid = isValidImageFileFormat(file);
            if (isValid) {
                let fileName = file.name;
                fileToBase64(file).then((base64String) => {
                    uploadProfileImageForWeb(base64String, fileName);
                });
            } else {
                showToast(I18n.t(messagePath + 'invalidFormat'));
            }
        }
    }

    const fileToBase64 = async (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (e) => reject(e)
        })

    const uploadProfileImageForWeb = (base64String, fileName) => {
        base64String = base64String.replace('data:image/png;base64,', '');
        base64String = base64String.replace('data:image/jpeg;base64,', '');
        base64String = base64String.replace('data:image/jpg;base64,', '');
        base64String = base64String.replace('data:image/gif;base64,', '');
        let source = { uri: base64String, fileName: fileName, isBase64: true, isFromWeb: true };
        uploadProfileImage(source);
    }

    if (isMobile()) {
        return getViewForMobile();
    } else {
        return getViewForWeb();
    }

}
export default ProfilePicHolder;