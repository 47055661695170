//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet ,Switch} from 'react-native';
import { colors } from '../../styles/colors';
import {Slider} from '@sharcoux/slider';
import { scale2 } from '../../styles/Scaling';

// create a component
const CustomSlider = (props) => {
    return (
        <Slider
            //  disabled={props.disableTemperatureSlider}
            value={props.value
            }
            onValueChange={value => {
               props.onValueChange(value)
            }}
            minimumValue={props.minimumValue}
            maximumValue={props.maximumValue}
            step={0.2}
            style={{marginTop: -2,marginLeft :0}}
            trackStyle={{height: 3,width:scale2(250)}}
            minimumTrackTintColor={colors.primary_blue}
            maximumTrackTintColor={'rgba(63, 140, 255, 0.15)'}
         //   thumbImage={require('../../assets/images/slider-thumb.png')}
            thumbStyle={{
                //marginLeft: -3,
            //    width: 30,
              //  height: 25,
                //paddingTop: 2.5,
            }}
            thumbTintColor={colors.primary_blue}

    />
    );
};

// define your styles
const styles = StyleSheet.create({

});

//make this component available to the app
export default CustomSlider;
