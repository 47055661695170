import {getBaseUrl} from '../components/BaseComponent';
import * as DataManager from '../repository/DataManager';
import {
  SP_CREDENTIALS,
  SP_NOTIFICATION_COUNT,
  SP_UNREAD_NOTIFICATION_LIST,
} from '../repository/SharedPreferenceHelper';
import {getHeaders} from '../utils/AppUtils';
import axios from 'axios';
import {showLog} from '../models/logModel';

export const setUnreadNotificationCount = value => {
  showLog('Fri4Nov setUnreadNotificationCount value: ' + value);
  DataManager.setString(SP_NOTIFICATION_COUNT, value.toString());
};

export const getUnreadNotificationCount = async () => {
  let value = await DataManager.getString(SP_NOTIFICATION_COUNT);
  showLog('Fri4Nov getUnreadNotificationCount value: ' + value);
  if (value && value > 0) return parseInt(value);
  else return 0;
};

export const fetchNotificationCountData = async () => {
  showLog('Fri4Nov fetchNotificationCountData');
  let userCredentials = await DataManager.getJson(SP_CREDENTIALS);
  if (!userCredentials || !userCredentials.authToken) return;
  showLog('Fri4Nov fetchNotificationCountData userCredentials');
  var header = getHeaders();
  header['authorization'] = userCredentials.authToken;
  header['api_key'] = userCredentials.api_key;
  let appUserId = userCredentials.appUserId;
  try {
    let url = getBaseUrl();
    url = url + 'boot?appUserId=' + appUserId;
    const response = await axios.get(url, {
      headers: header,
    });
    showLog(
      'Fri4Nov fetchNotificationCountData response ' + JSON.stringify(response),
    );
    if (response && response.status == 200 && response.data.success) {
      var notificationCount = response.data.data['notification_count'];
      showLog(
        'Fri4Nov fetchNotificationCountData notificationCount: ' +
          notificationCount,
      );
      await setUnreadNotificationCount(notificationCount);
      // this.showNotificationCount();
      return;
    } else {
      showLog('Fri4Nov fetchNotificationCountData response Failed');
    }
    return;
  } catch (e) {
    showLog('Fri4Nov fetchNotificationCountData error catch');

    showLog(e);
    return;
  }
};
