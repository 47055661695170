import React, { useEffect, useState } from 'react';
import {
  View,
} from 'react-native';
import { connect } from 'react-redux';
import CustomTilesPageView from '../component/CustomTilesPageView';
import { getWellBeingData } from '../../../redux/reducers/WellbeingReducer';
import * as DashboardModel from '../../../models/dashboardModel';
import I18n from '../../../utils/I18n';
import { fetchCustomTilesData, getCultureCustomTiles, getWellBeingCustomTiles } from '../../../repository/CustomTilesDataHelper';

const CultureScreen = props => {
  const [tilesData, setTilesData] = useState([]);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    loadTilesData();
  }, []);

  const loadTilesData = async () => {
    let tilesArray = await getCultureCustomTiles();
    if (tilesArray && tilesArray.length > 0)
      setTilesData(tilesArray);
    else
      setTilesData([]);
  };

  const goToLinks = async () => {
    let dashboardLinks = await DashboardModel.getDashboardLinks();
    props.navigation.navigate('LinkScreen', {
      links: dashboardLinks,
    });
  };

  const onPullToRefresh = async () => {
    setRefreshing(true);
    await fetchCustomTilesData();
    await loadTilesData();
    setRefreshing(false);
  }

  return (
    <View style={{ flex: 1 }}>
      <CustomTilesPageView
        navigation={props.navigation}
        name={''}
        tabType={I18n.t('menuItemLabel.' + 'menu_culture')}
        spinnerVisibility={false}
        selectedPickerIndex={0}
        setSelectedPickerIndex={() => { }}
        loadCheckInData={() => { }}
        setBlueTick={() => { }}
        isVaccinationHistoryExist={() => { }}
        navigateToNotificationScreen={() => {
          props.navigation.navigate('NotificationListScreen');
        }}
        navigateToSettingScreen={() => {
          props.navigation.navigate('menuStack');
        }}
        tilesArrayDisplay={tilesData}
        onTileClick={(url, inAppBrowserType,title) => {
          props.navigation.navigate('TabComponentsWebView', {
            url: url,
            inAppBrowserType: inAppBrowserType,
            title: title
          });
          //open tabwebview on the url
        }}
        goToLinks={() => goToLinks()}
        refreshing={refreshing}
        onStartRefresh={() => { onPullToRefresh() }}
      />
    </View>
  );
};
const mapStateToProps = state => {
  return {
    customTilesData: state.WellBeingReducer,
  };
};
const mapDispatchToProps = {
  getWellBeingData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CultureScreen);
