import {
  getHealthCustomTiles,
  getSafetyCustomTiles,
  getWealthCustomTiles,
  getWellBeingCustomTiles,
} from '../repository/CustomTilesDataHelper';
import {isJsonObjectEmpty} from '../utils/JsonUtils';
import {RoutesNameKeys} from '../utils/StringKeys';
import {getFormattedCheckInData} from './checkInDataModel';
import {showLog} from './logModel';

export const getInitialRouteName = () => {
  if (global.todayTabVisibility) return RoutesNameKeys.TAB_HOME;
  else if (global.wellBeingTabVisibility) return RoutesNameKeys.TAB_WELLBEING;
  else if (global.healthTabVisibility) return RoutesNameKeys.TAB_HEALTH;
  else if (global.wealthTabVisibility) return RoutesNameKeys.TAB_WEALTH;
};

export const getInitialTabRoute = async () => {
  let todayTab = await isTodayTabActive();
  let wellBeingTab = await isWellBeingTabActive();
  let healthTab = await isHealthTabActive();
  let wealthTab = await isWealthTabActive();

  global.todayTabVisibility = todayTab;
  global.wellBeingTabVisibility = wellBeingTab;
  global.healthTabVisibility = healthTab;
  global.wealthTabVisibility = wealthTab;

  return 'Health';
  // if (todayTab) return RoutesNameKeys.TAB_HOME;
  // else if (wellBeingTab) return RoutesNameKeys.TAB_WELLBEING;
  // else if (healthTab) return RoutesNameKeys.TAB_HEALTH;
  // else if (wealthTab) return RoutesNameKeys.TAB_WEALTH;
  // else return 'NoActiveTileFoundPage';
};

//Below functions are called after down sync
export const isTodayTabActive = async () => {
  //1. Get Default Tiles status
  let d = await getFormattedCheckInData();
  showLog('isTodayTabActive d ' + JSON.stringify(d));
  if (!d.subProfile || d.subProfile.length == 0) return false;

  let profile = d.subProfile[0];
  showLog('isTodayTabActive profile ' + JSON.stringify(profile));
  let case1 = profile.isTestSubmittionEnabled;
  let case2 = profile.data && profile.data.length > 0;
  let case3 = profile.vaccineData && !isJsonObjectEmpty(profile.vaccineData);
  showLog('isTodayTabActive case3 ' + case3);

  //2. Get Custom Tiles Status
  let tilesArray = await getSafetyCustomTiles();
  let case4 = tilesArray && tilesArray.length > 0;

  if (case1 || case2 || case3 || case4) {
    return true;
  }

  return false;
};

export const isWellBeingTabActive = async () => {
  let tilesArray = await getWellBeingCustomTiles();
  if (tilesArray && tilesArray.length > 0) return true;
  return false;
};

export const isHealthTabActive = async () => {
  //1. Get Default Tiles status
  let d = await getFormattedCheckInData();
  showLog('isTodayTabActive d ' + JSON.stringify(d));

  if (!d.subProfile || d.subProfile.length == 0) return false;

  let profile = d.subProfile[0];
  showLog('isHealthTabActive profile ' + JSON.stringify(profile));
  let case1 = profile.isTestSubmittionEnabled;
  let case2 = profile.vaccineData && !isJsonObjectEmpty(profile.vaccineData);
  showLog('isHealthTabActive case1 ' + case1);
  showLog('isHealthTabActive case2 ' + case2);

  let tilesArray = await getHealthCustomTiles();
  let case3 = tilesArray && tilesArray.length > 0;

  if (case1 || case2 || case3) {
    return true;
  }

  return false;
};

export const isWealthTabActive = async () => {
  let tilesArray = await getWealthCustomTiles();
  if (tilesArray && tilesArray.length > 0) return true;
  return false;
};
