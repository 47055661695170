export const CategoryKeys = {
  SAFETY_CATEGORY: 'SAFETY',
  HEALTH_CATEGORY: 'HEALTH',
  WEALTH_CATEGORY: 'WEALTH',
  WELLBEING_CATEGORY: 'WELL_BEING',
  ENGAGEMENT_CATEGORY: 'ENGAGEMENT',
  CULTURE_CATEGORY: 'CULTURE',
  BENEFITS_CATEGORY: 'WEALTH',
  TAB_STATE: 'TAB_STATE'
};

export const RoutesNameKeys = {
  TAB_HOME: 'Home',
  TAB_WELLBEING: 'Wellbeing',
  TAB_HEALTH: 'Health',
  TAB_WEALTH: 'Wealth',
};
