import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  StatusBar,
  Text,
  View,
  Platform,
  TouchableOpacity,
  Image,
  Dimensions
} from 'react-native';

import { SafeAreaView } from 'react-native-safe-area-context';
import { colors } from '../../../styles/colors';
import { textStyles } from '../../../styles/textFontStyles';
import { globalStyles } from '../../../config/globalStyles';
import { getText } from '../../../config/Strings/StringUtil';
import { WebView } from 'react-native-webview';
import { BackHandler } from 'react-native';
import { showLog } from '../../../models/logModel';
// import CookieManager from '@react-native-community/cookies';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { verticalScale2 } from '../../../styles/Scaling';
import Events from '../../../router/events';
import { getCookies, getDomainFromUrl, saveCookies } from '../model/SessionCookies';
import { show } from 'modal-react-native-web/dist/ariaAppHider';
import { onLoadEndHelper } from '../../../utils/CookieManagerHelper';
import DashboardHeaderComponent from '../../Dashboard/components/DashboardHeaderComponent';

const TabComponentsWebView = props => {
  const [canGoBack, setCanGoBack] = useState(false);
  const [isReady, setReady] = useState(false);
  const [cookiesString, setCookieString] = useState('');

  const [actionBarVisibility, setActionBarVisibility] = useState(false);

  const url = props.navigation.state.params.url;
  const inAppBrowserType = props.navigation.state.params.inAppBrowserType;
  const title = props.navigation.state.params.title;
  const webview = useRef();

  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", handleBack);
    return () => {
      BackHandler.removeEventListener("hardwareBackPress", handleBack);
    };
  }, [canGoBack]);

  const handleBack = () => {
    Events.publish('MODE_CHANGE', { mode: 'portrait' });
    if (canGoBack && webview.current) {
      webview.current.goBack();
      return true; // prevent default behavior (exit app)
    } else {
      props.navigation.pop();
      return true;
    }
  };

  const closeWebView = () => {
    Events.publish('MODE_CHANGE', { mode: 'portrait' });
    props.navigation.pop();
  };

  useEffect(() => {
    onPageOpen();
  }, []);

  const onPageOpen = async () => {
    if (inAppBrowserType && inAppBrowserType == 'BRANDED') {
      //Hide bottom menu and Show Action bar.
      setActionBarVisibility(true);
      Events.publish('MODE_CHANGE', { mode: 'landscape' });
    } else if (inAppBrowserType && inAppBrowserType == 'BOTTOM_MENU') {
      //Hide Action bar and show bottom menu.
      setActionBarVisibility(false);
      Events.publish('MODE_CHANGE', { mode: 'portrait' });
    }

    let domain = getDomainFromUrl(url);
    let savedCookies = await provideMeSavedCookies(domain);
    let cookiesString = jsonCookiesToCookieString(savedCookies);
    showLog('TRP onPageOpen get SavedCookies for domain: ' + domain + '  ' + JSON.stringify(cookiesString));
    const JSESSIONID = await AsyncStorage.getItem('JSESSIONID');
    showLog('TRP onPageOpen get JSESSIONID for domain: ' + domain + '  ' + JSESSIONID);
    if (JSESSIONID) {
      cookiesString += `JSESSIONID=${JSESSIONID};`;
    }
    setCookieString(cookiesString);
    setReady(true);
  }

  const onLoadProgress = event => {
    setCanGoBack(event.nativeEvent.canGoBack);
  };

  const onNavigationStateChange = (navState) => {
    setCurrentUrl(navState.url);
  };

  //Handle cookies
  const onLoadEnd = syntheticEvent => {
    let successUrl = url;
    // let successUrl = 'https://dev.clear360.com/org/list';

    if ((true || currentUrl == successUrl)) {
      if (Platform.OS == 'ios') {
        CookieManager.get(currentUrl, true).then(res => {
          // AsyncStorage.setItem('savedCookies', JSON.stringify(res));
          let domain = getDomainFromUrl(currentUrl);
          showLog('TRP onLoadEnd saving cookies for domain : ' + domain + '  ' + JSON.stringify(res));
          saveCookies(domain, JSON.stringify(res));
          if (res.JSESSIONID) {
            showLog('TRP onLoadEnd saving JSESSIONID for domain : ' + domain + '  ' + res.JSESSIONID.value);
            AsyncStorage.setItem('JSESSIONID', res.JSESSIONID.value);
          }
        });
      } else {
        CookieManager.get(currentUrl, true).then(res => {
          // AsyncStorage.setItem('savedCookies', JSON.stringify(res));
          let domain = getDomainFromUrl(currentUrl);
          saveCookies(domain, JSON.stringify(res));
          // if (res.JSESSIONID) {
          //   AsyncStorage.setItem('JSESSIONID', res.JSESSIONID.value);
          // }
        });
      }
    } else {
    }
  };

  const jsonCookiesToCookieString = json => {
    let cookiesString = '';
    for (let [key, value] of Object.entries(json)) {
      cookiesString += `${key}=${value.value}; `;
    }
    return cookiesString;
  };

  const provideMeSavedCookies = async (domain) => {
    try {
      let cookiesString = await getCookies(domain);
      if (cookiesString !== null) {
        return JSON.parse(cookiesString);
      }
    } catch (error) {
      return {};
    }
  };

  return isReady ? (
    <SafeAreaView
      edges={['top']}
      style={{
        flex: 1,
        backgroundColor: colors.drawerMenuColor,
      }}>
      <View style={[globalStyles.rootContainer, {
        paddingLeft: 0,
        paddingRight: 0,
      }]}>
        <StatusBar
          backgroundColor={colors.drawerMenuColor}
          barStyle="light-content"
        />

        {false && actionBarVisibility && (
          <View
            style={[
              textStyles.headerOuter,
              {
                height: verticalScale2(50),
                justifyContent: 'flex-start',
                borderBottomWidth: 0.5,
                borderColor: colors.border_color
              },
            ]}>
            <TouchableOpacity
              style={styles.backImageOuter}
              activeOpacity={0.8}
              onPress={() => {
                // handleBack();
                closeWebView();
              }}>
              <Image
                style={[
                  styles.backImageStyle,
                  { tintColor: colors.primary_blue },
                ]}
                source={require('../../../assets/images/icon_back.png')}
                tintColor={colors.primary_blue}
              />
            </TouchableOpacity>

            <Text
              style={[
                textStyles.InterSemiBold,
                {
                  fontSize: verticalScale2(20),
                  color: colors.blueWeb
                },
              ]}>
              {title}
            </Text>

          </View>
        )}

        <DashboardHeaderComponent
          navigation={props.navigation}
          tabType={title}
          showBackButton={true}
        />


        {url == null ? (
          <View
            style={{
              width: '100%',
              height: '80%',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Text style={[textStyles.InterMedium, { color: 'black' }]}>
              {getText('serverError')}
            </Text>
          </View>
        ) : (
          Platform.OS === 'web' ? <iframe src={url} style={{width:"100%", border: 0, height: Dimensions.get("window").height}} /> :
          <View style={{ flex: 1, }}>
            <WebView
              ref={webview}
              textZoom={100}
              originWhitelist={['*']}
              source={{
                uri: url,
                headers: {
                  Cookie: cookiesString,
                },
              }}
              style={{}}
              onLoadEnd={onLoadEnd}
              sharedCookiesEnabled={true}
              javaScriptEnabled={true}
              scalesPageToFit={Platform.OS == 'android' ? false : true}
              domStorageEnabled={true}
              startInLoadingState={true}
              thirdPartyCookiesEnabled={true}
              onNavigationStateChange={onNavigationStateChange}
              onLoadProgress={(event) => onLoadProgress(event)}
              userAgent="Mozilla/5.0 (Linux; Android 6.0.1; SM-G920V Build/MMB29K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/52.0.2743.98 Mobile Safari/537.36"
            />
          </View>
        )}
      </View>
    </SafeAreaView>
  ) : null;
};
const styles = {
  root: {
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'flex-end',
    paddingLeft: 50,
    paddingRight: 50,
  },
  backImageOuter: {
    width: 38,
    height: 38,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor:colors.red,
    marginLeft: 10
  },
  backImageStyle: {
    width: verticalScale2(20),
    height: verticalScale2(20),
    tintColor: '#000',

  },
};
export default TabComponentsWebView;
