import I18n from "react-native-i18n";
import en from "../translations/en.js";
import fr from "../translations/fr.js";
import es from "../translations/es.js";
import { NativeModules, Platform } from 'react-native';
import { isMobile } from "./AppUtils.js";

I18n.fallbacks = true;
I18n.translations = {
  en,
  fr,
  es
};
I18n.defaultLocale = 'en';
var locale;
// if (isMobile()) {
//   locale =
//   Platform.OS === 'ios'
//     ? NativeModules.SettingsManager.settings.AppleLocale
//     : NativeModules.I18nManager.localeIdentifier;
// } else {
//   locale = navigator.language;
// }

//alert("locale=" + locale)
//I18n.locale = locale.substring(0, 2);
export default I18n;