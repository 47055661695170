import { showLog } from "../models/logModel";

export function parseStringToJson(text) {
    return JSON.parse(text);
}

export function parseJsonToString(jsonObj) {
    return JSON.stringify(jsonObj);
}

export function isJsonObjectEmpty(jsonObj) {
    return Object.keys(jsonObj).length === 0;
}

