import React, { useEffect, useState } from 'react';
import { BackHandler } from 'react-native';
import { connect } from 'react-redux';
import CustomTilesPageView from '../component/CustomTilesPageView';
import { getWellBeingData } from '../../../redux/reducers/WellbeingReducer';
import * as DashboardModel from '../../../models/dashboardModel';
import { GLOBAL } from '../../../../App';
import I18n from '../../../utils/I18n';
import { fetchCustomTilesData, getWealthCustomTiles, getWellBeingCustomTiles } from '../../../repository/CustomTilesDataHelper';
import { getInitialRouteName } from '../../../models/TabDataModel';
import { RoutesNameKeys } from '../../../utils/StringKeys';

const WellBeingScreen = props => {
  const [userName, setUserName] = useState('');
  const [tilesData, setTilesData] = useState([]);
  const [refreshing, setRefreshing] = useState(false);


  useEffect(() => {
    loadDashboardData();
    loadTilesData();
  }, []);

  useEffect(() => {
    const unsubscribe = props.navigation.addListener('didFocus', onScreenFocus);
    return () => {
      unsubscribe.remove();
    };
  }, [props.navigation]);

  useEffect(() => {
    const unsubscribe_didBlur = props.navigation.addListener('didBlur', onScreenBlured);
    return () => {
      unsubscribe_didBlur.remove();
    };
  }, [props.navigation]);

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', handleBackButtonClick);
    return () => {
      BackHandler.removeEventListener('hardwareBackPress', handleBackButtonClick);
    };
  }, [props.navigation]);

  const onScreenFocus = () => {
    BackHandler.addEventListener('hardwareBackPress', handleBackButtonClick);
    loadDashboardData();
  }

  const onScreenBlured = () => {
    BackHandler.removeEventListener('hardwareBackPress', handleBackButtonClick);
  }


  const handleBackButtonClick = () => {
    if (getInitialRouteName() == RoutesNameKeys.TAB_WELLBEING) {
      BackHandler.exitApp();
    } else {
      props.navigation.navigate(getInitialRouteName());
    }
    return true;
  }

  const loadDashboardData = async () => {
    let data = await DashboardModel.getDashboardData();
    setUserName(data[GLOBAL.SELECTED_USER_INDEX].fullName);
  };

  const loadTilesData = async () => {
    let tilesArray = await getWellBeingCustomTiles();
    if (tilesArray && tilesArray.length > 0)
      setTilesData(tilesArray);
    else
      setTilesData([]);
  };


  const goToLinks = async () => {
    let dashboardLinks = await DashboardModel.getDashboardLinks();
    props.navigation.navigate('LinkScreen', {
      links: dashboardLinks,
    });
  };

  const onPullToRefresh = async () => {
    setRefreshing(true);
    await fetchCustomTilesData();
    await loadTilesData();
    setRefreshing(false);
  }

  return (
    <CustomTilesPageView
      navigation={props.navigation}
      name={userName}
      tabType={I18n.t('menuItemLabel.' + 'menu_wellbeing')}
      spinnerVisibility={false}
      selectedPickerIndex={0}
      setSelectedPickerIndex={() => { }}
      loadCheckInData={() => { }}
      setBlueTick={() => { }}
      isVaccinationHistoryExist={() => { }}
      navigateToNotificationScreen={() => {
        props.navigation.navigate('NotificationListScreen');
      }}
      navigateToSettingScreen={() => {
        props.navigation.navigate('menuStack');
      }}
      tilesArrayDisplay={tilesData}
      onTileClick={(url, inAppBrowserType, title) => {
        props.navigation.navigate('TabComponentsWebView', {
          url: url,
          inAppBrowserType: inAppBrowserType,
          title: title
        });
      }}
      goToLinks={() => goToLinks()}
      refreshing={refreshing}
      onStartRefresh={() => { onPullToRefresh() }}
    />
  );
};
const mapStateToProps = state => {
  return {
    customTilesData: state.WellBeingReducer,
  };
};
const mapDispatchToProps = {
  getWellBeingData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WellBeingScreen);
