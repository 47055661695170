import React, { useEffect, useState } from 'react';
import {
  Modal,
  StyleSheet,
  TouchableOpacity,
  View,
  Text,
  Platform,
} from 'react-native';

import { ScrollView } from 'react-native-gesture-handler';
import SafeAreaView from 'react-native-safe-area-view';
import I18n from '../utils/I18n';
import DrawerMenuBadge from '../components/DrawerMenuBadge';
import DrawerMenuHeader from '../components/DrawerMenuHeader';
import DrawerMenuFooter from '../components/DrawerMenuFooter';
import Event from '../router/events';
import { StackActions } from 'react-navigation';
import * as DashboardModel from '../models/dashboardModel';
import { EVENT_SHOW_LOGOUT_CONFIRMATION } from '../components/LogoutConfirmDialog';
import {
  getBenefitsCustomTiles,
  getCultureCustomTiles,
  getEngagementCustomTiles,
  getHealthCustomTiles,
  getWellBeingCustomTiles,
} from '../repository/CustomTilesDataHelper';
import { isUndefinedOrNull } from '../config/Strings/StringUtil';
import * as checkInDataModel from '../models/checkInDataModel';
import { showLog } from '../models/logModel';
const languagepath = 'menuItemLabel.';

const DrawerCustomComponent = props => {
  const { renderIcon, activeTintColor, inactiveTintColor, navigation } = props;
  const { routes, index: activeRouteIndex } = navigation.state;
  const [pages, setPages] = useState(routes);

  useEffect(() => {
    configureMenuItemVisibility();
  }, []);

  useEffect(() => {
    let eventHandle = Event.subscribe(
      'UPDATE_DRAWER_MENU_CUSTOM_TILES_COUNT',
      data => {
        configureMenuItemVisibility();
      },
    );
    return () => eventHandle.remove();
  }, []);

  const configureMenuItemVisibility = async () => {
    let newPages = JSON.parse(JSON.stringify(routes));

    //Wellbeing
    let visible = await getWellbeingVisibility();
    if (!visible) newPages = hidePage('Wellbeing', newPages);

    //Health
    visible = await getHealthVisibilty();
    if (!visible) newPages = hidePage('Health', newPages);

    //Engagement
    visible = await getEngagementVisibility();
    if (!visible) newPages = hidePage('Engagement', newPages);

    //Culture
    visible = await getCultureVisibilty();
    if (!visible) newPages = hidePage('Culture', newPages);

    //Benefits
    visible = await getBenefitsVisibility();
    if (!visible) newPages = hidePage('Benefits', newPages);

    setPages(newPages);
  };

  const hidePage = (pageTag, pagesArray) => {
    let idx = pagesArray.findIndex(page => page.key == pageTag);
    pagesArray.splice(idx, 1);
    return pagesArray;
  };

  const getWellbeingVisibility = async () => {
    let jsonObj = await getWellBeingCustomTiles();
    return jsonObj && jsonObj.length > 0;
  };

  const getHealthVisibilty = async () => {
    let isVaccEnabled = false;
    let isTestingEnabled = false;
    let customTilesCount = 0;

    let userDashboardData = await DashboardModel.getDashboardData();
    if (userDashboardData && userDashboardData.length > 0) {
      let obj = userDashboardData[0];
      if (obj && obj.immunization_dashboard_status) {
        let status = obj.immunization_dashboard_status.app_dashboard_status;
        isVaccEnabled = !isUndefinedOrNull(status);
      }
    }

    let data = await checkInDataModel.getFormattedCheckInData();
    isTestingEnabled = data.subProfile[0].isTestSubmittionEnabled;

    let jsonObj = await getHealthCustomTiles();

    if (jsonObj && jsonObj.length > 0) customTilesCount = jsonObj.length;
    if (isVaccEnabled) customTilesCount = customTilesCount + 1;
    if (isTestingEnabled) customTilesCount = customTilesCount + 1;

    // setHealthVisibility(customTilesCount > 0);
    // if (customTilesCount == 0) hidePage('Health');
    return customTilesCount > 0;
  };

  const getEngagementVisibility = async () => {
    let jsonObj = await getEngagementCustomTiles();
    return jsonObj && jsonObj.length > 0;
  };

  const getCultureVisibilty = async () => {
    let jsonObj = await getCultureCustomTiles();
    return jsonObj && jsonObj.length > 0;
  };

  const getBenefitsVisibility = async () => {
    let jsonObj = await getBenefitsCustomTiles();
    return jsonObj && jsonObj.length > 0;
  };

  const getMenuItem = (routeIndex, route, isRouteActive, tintColor) => {
    showLog(
      'getMenuItem routeIndex: ' +
      routeIndex +
      ' route.key: ' +
      route.key +
      ' isRouteActive: ' +
      isRouteActive,
    );
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingHorizontal: 22,
        }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {renderIcon({ route, focused: isRouteActive, tintColor })}

          <Text
            style={[
              styles.menuItemTextStyle,
              {
                color: tintColor,
                marginLeft: 17,
              },
            ]}>
            {tabBarLabelTitle(route.key)}
          </Text>
        </View>

        <DrawerMenuBadge routeKey={route.key} activeRouteIndex={routeIndex} />
      </View>
    );
  };

  const tabBarLabelTitle = route => {
    if (route == 'Home') {
      return I18n.t(languagepath + 'menu_productivity');
    } else if (route == 'Wellbeing') {
      return I18n.t(languagepath + 'menu_wellbeing');
    } else if (route == 'Health') {
      return I18n.t(languagepath + 'menu_health_safety');
    } else if (route == 'Engagement') {
      return I18n.t(languagepath + 'menu_engagement');
    } else if (route == 'Culture') {
      return I18n.t(languagepath + 'menu_culture');
    } else if (route == 'Benefits') {
      return I18n.t(languagepath + 'menu_benefits');
    } else if (route == 'Notifications') {
      return I18n.t(languagepath + 'menu_notification');
    } else if (route == 'Settings') {
      return I18n.t(languagepath + 'menu_settings');
    } else if (route == 'Profile') {
      return I18n.t(languagepath + 'menu_profile');
    } else if (route == 'Logout') {
      return I18n.t(languagepath + 'menu_logout');
    } else {
      return route;
    }
  };

  const renderSectionTitle = label => {
    return <Text style={styles.menuSectionLabelTextStyle}>{label}</Text>;
  };

  const renderMenuItem = (routeIndex, route, isRouteActive, tintColor) => {
    return (
      <TouchableOpacity
        activeOpacity={0.8}
        key={routeIndex}
        onPress={() => {
          if (route.key == 'Logout') {
            Event.publish(EVENT_SHOW_LOGOUT_CONFIRMATION);
          } else {
            if (route?.routes?.length > 1) {
              const popAction = StackActions.pop(1);
              navigation.dispatch(popAction);
            } else navigation.navigate(route.key);
          }
        }}
        style={{
          marginBottom: 21,
        }}>
        {route.key == 'Home'
          ? renderSectionTitle(I18n.t(languagepath + 'menu_services'))
          : null}
        {route.key == 'Notifications' ? renderSeprator() : null}
        {route.key == 'Notifications'
          ? renderSectionTitle(I18n.t(languagepath + 'menu_account'))
          : null}
        {getMenuItem(routeIndex, route, isRouteActive, tintColor)}
        {route.key == 'Logout' ? <View style={{ height: 22 }} /> : null}
        {route.key == 'Logout' ? renderSeprator() : null}
      </TouchableOpacity>
    );
  };

  const renderSeprator = () => {
    return (
      <View
        style={{
          height: 1,
          width: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        }}
      />
    );
  };

  const ifPageActive = (pageName, activeRouteIndex) => {
    return routes[activeRouteIndex].key == pageName;
  };

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <SafeAreaView
        style={styles.container}
        forceInset={{ top: 'always', horizontal: 'never' }}>
        <View>
          <View>
            <DrawerMenuHeader
              navigation={navigation}
            />
            {renderSeprator()}
            {pages.map((route, routeIndex) => {
              showLog(
                'pages.map routeIndex: ' +
                routeIndex +
                ' activeRouteIndex:' +
                activeRouteIndex +
                ' route.key: ' +
                route.key,
              );
              const isRouteActive = ifPageActive(route.key, activeRouteIndex);
              const tintColor = isRouteActive
                ? activeTintColor
                : inactiveTintColor;
              return renderMenuItem(
                routeIndex,
                route,
                isRouteActive,
                tintColor,
              );
            })}
            <DrawerMenuFooter />
          </View>
        </View>
      </SafeAreaView>
    </ScrollView>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  menuItemTextStyle: {
    fontFamily:
      Platform.OS === 'web'
        ? 'Inter-SemiBold'
        : Platform.OS === 'android'
          ? 'Inter-Medium'
          : 'Inter-Medium',
    fontWeight: '500',
    fontSize: 15,
  },
  menuSectionLabelTextStyle: {
    fontFamily:
      Platform.OS === 'web'
        ? 'Inter-SemiBold'
        : Platform.OS === 'android'
          ? 'Inter-Bold'
          : 'Inter-Bold',
    fontWeight: '700',
    fontSize: 10,
    marginTop: 18,
    marginBottom: 16,
    color: 'rgba(255, 255, 255, 0.7)',
    paddingHorizontal: 22,
  },
});
export default DrawerCustomComponent;
