import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import {connect} from 'react-redux';
import * as DashboardModel from '../../../models/dashboardModel';
import {getWellBeingData} from '../../../redux/reducers/WellbeingReducer';
import I18n from '../../../utils/I18n';
import {
  fetchCustomTilesData,
  getHealthCustomTiles,
} from '../../../repository/CustomTilesDataHelper';
import {BackHandler} from 'react-native';
import {getInitialRouteName} from '../../../models/TabDataModel';
import {RoutesNameKeys} from '../../../utils/StringKeys';
import HealthTilesPageView from '../component/HealthTilesPageView';
import * as checkInDataModel from '../../../models/checkInDataModel';
import {getUserById, getVacinationProtocol} from '../../../models/usersModel';
import {
  getUserHistoryExemptionsRecords,
  getUserHistoryVaccination,
  isLatestVaccinationRecordOfToday,
  setVaccinationEnabled,
} from '../../../models/VaccineModel';
import {isLatestTestingRecordOfToday} from '../../TestResult/model/TestRecordModel';
import Events from '../../../router/events';
import {isUndefinedOrNull} from '../../../config/Strings/StringUtil';
import * as DataManager from '../../../repository/DataManager';
import {SP_CREDENTIALS} from '../../../repository/SharedPreferenceHelper';
import {isInternetAvailable} from '../../../utils/AppUtils';
import {fetchNotificationCountData} from '../../../pushNotifications/NotificationModel';
const TILE_VACCIANTION = 'vaccination';
const TILE_TESTING = 'testing';

const HealthScreen = props => {
  const [userName, setUserName] = useState('');
  const [userDashboardData, setUserDashboardData] = useState();
  const [blueTickOnVaccination, setBlueTickOnVaccination] = useState(false);
  const [blueTickOnTesting, setBlueTickOnTesting] = useState(false);
  const [disableVacciantionOverlay, setDisableVacciantionOverlay] = useState(
    false,
  );
  const [checkInData, setCheckInData] = useState();
  const [overlay, setOverlay] = useState(false);
  const [tilesArray, setTilesArray] = useState([
    {
      type: TILE_VACCIANTION,
      visibility: false,
    },
    {
      type: TILE_TESTING,
      visibility: false,
    },
  ]);
  const [customTilesArray, setCustomTilesArray] = useState([]);
  const [tilesArrayDisplay, setTilesArrayDisplay] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [showEvalutingDialog, setShowEvalutingDialog] = useState(false);

  //Start: Default callbacks
  useEffect(() => {
    const unsubscribe = props.navigation.addListener('didFocus', onScreenFocus);
    return () => {
      unsubscribe.remove();
    };
  }, [props.navigation]);

  useEffect(() => {
    const unsubscribe_didBlur = props.navigation.addListener(
      'didBlur',
      onScreenBlured,
    );
    return () => {
      unsubscribe_didBlur.remove();
    };
  }, [props.navigation]);

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', handleBackButtonClick);
    return () => {
      BackHandler.removeEventListener(
        'hardwareBackPress',
        handleBackButtonClick,
      );
    };
  }, [props.navigation]);

  useEffect(() => {
    const subscribe1 = Events.subscribe('EVENT_SYNC_COMPLETED', onSyncComplete);
    const subscribe2 = Events.subscribe(
      'EVENT_DATA_SYNC_DOWN_COMPLETED',
      onSyncDownComplete,
    );
    return () => {
      subscribe1.remove();
      subscribe2.remove();
    };
  }, [props.navigation]);

  useEffect(() => {
    updateNotifcationbadge();
    onScreenFocus();
    loadCheckInData();
  }, []);

  const onSyncComplete = () => {
    if (!global.totalCheckInSubmitted || global.totalCheckInSubmitted <= 0) {
      let doHildeEvaluatingDialog = true;
      getUserDashboardDataFromServer(doHildeEvaluatingDialog);
    }
  };

  const onSyncDownComplete = () => {
    loadCheckInData();
  };

  const updateNotifcationbadge = async () => {
    await fetchNotificationCountData();
    Events.publish('EVENT_NEW_NOTIFICATION_RECEIVED_FROMDEEPLINK');
  };

  const handleBackButtonClick = () => {
    if (getInitialRouteName() == RoutesNameKeys.TAB_HEALTH) {
      BackHandler.exitApp();
    } else {
      props.navigation.navigate(getInitialRouteName());
    }
    return true;
  };

  //End: Default callbacks

  const onScreenFocus = async () => {
    Events.publish('MODE_CHANGE', {mode: 'portrait'});
    BackHandler.addEventListener('hardwareBackPress', handleBackButtonClick);
    setScreenHeader();
    await loadTilesData();
    getDashboardDataLocal();
    getUserDashboardDataFromServer();
  };

  const onScreenBlured = () => {
    BackHandler.removeEventListener('hardwareBackPress', handleBackButtonClick);
  };

  useEffect(() => {
    if (userDashboardData && userDashboardData.length > 0)
      afterDashboadDataFetchedFromLocal();
  }, [userDashboardData]);

  //======== Custom Functions =====================

  const setScreenHeader = async () => {
    let data = await DashboardModel.getDashboardData();
    setUserName(data[0].fullName);
  };

  const loadTilesData = async () => {
    let customTilesList = await getHealthCustomTiles();
    if (customTilesList && customTilesList.length > 0)
      setCustomTilesArray(customTilesList);
    else setCustomTilesArray([]);
  };

  const goToLinks = async () => {
    let dashboardLinks = await DashboardModel.getDashboardLinks();
    props.navigation.navigate('LinkScreen', {
      links: dashboardLinks,
    });
  };

  //================= Vaccination Tiles related data and functions ===========
  const getDashboardDataLocal = async doHildeEvaluatingDialog => {
    let userDashboardData = await DashboardModel.getDashboardData();
    setUserDashboardData(JSON.parse(JSON.stringify(userDashboardData)));
  };

  const afterDashboadDataFetchedFromLocal = async doHildeEvaluatingDialog => {
    if (doHildeEvaluatingDialog) {
      displayEvaluatingDialog(false);
    }
    let appuserID = getSelectedUserId();

    // let appuserID = userDashboardData[0].appUserId;
    setBlueTick(appuserID);
    await isVaccinationHistoryExist(appuserID);
    let dashboardRecord = userDashboardData[0];
    if (dashboardRecord) {
      let v = await isLatestTestingRecordOfToday(dashboardRecord.latestTest);
      await checkInDataModel.setTestingGivenToday(appuserID, v);
      setBlueTick(appuserID);
    }
    setVaccinationTileVisibility();
  };

  const getUserDashboardDataFromServer = async doHildeEvaluatingDialog => {
    let userCrendtials = await DataManager.getJson(SP_CREDENTIALS);
    let appUserID = userCrendtials.appUserId;

    let list = await DashboardModel.getDashboardAPIData(appUserID);
    await DashboardModel.saveDashboardData(list);
    getDashboardDataLocal(doHildeEvaluatingDialog);
    if (list && list.length == 0) {
    } else {
      if (list[0].immunization_status) {
        setVaccinationEnabled(true);
      } else {
        setVaccinationEnabled(false);
      }
    }
  };

  const isVaccinationHistoryExist = async appUserId => {
    if (appUserId) {
      let records = await getUserHistoryVaccination(appUserId);
      let exepmtRecords = await getUserHistoryExemptionsRecords(appUserId);
      let isVaccinationRecordOfToday = await isLatestVaccinationRecordOfToday(
        appUserId,
      );
      await checkInDataModel.setVaccinationGivenToday(
        appUserId,
        isVaccinationRecordOfToday,
      );
      setBlueTick(appUserId);
      if (
        (!records || records.length == 0) &&
        (!exepmtRecords || exepmtRecords.length == 0)
      ) {
        setDisableVacciantionOverlay(true);
        return;
      }
    }
    setDisableVacciantionOverlay(false);
  };

  const getVaccnationStatus = () => {
    if (userDashboardData && userDashboardData.length > 0) {
      let obj = userDashboardData[0];
      if (obj && obj.immunization_dashboard_status) {
        return obj.immunization_dashboard_status.app_dashboard_status;
      } else {
        return;
      }
    }
  };

  const getVaccnationStatusData = () => {
    if (userDashboardData && userDashboardData.length > 0) {
      let obj = userDashboardData[0];
      if (obj && obj.immunization_dashboard_status) {
        return obj.immunization_dashboard_status;
      } else {
        return '';
      }
    }
  };

  const getTestingStatus = () => {
    if (userDashboardData && userDashboardData.length > 0) {
      let obj = userDashboardData[0];
      if (obj && obj.testing_dashboard_status) {
        return obj.testing_dashboard_status.displayText;
      } else {
        return '';
      }
    }
  };

  const getTestingStatusData = () => {
    if (userDashboardData && userDashboardData.length > 0) {
      let obj = userDashboardData[0];
      if (obj && obj.testing_dashboard_status) {
        return obj.testing_dashboard_status;
      } else {
        return '';
      }
    }
  };

  const setBlueTick = async selectedAppUserId => {
    let checkInTodayStatus = await checkInDataModel.getCheckInTodayStatus(
      selectedAppUserId,
    );
    setBlueTickOnVaccination(checkInTodayStatus.isVaccinationGivenToday);
    setBlueTickOnTesting(checkInTodayStatus.isTestingGivenToday);
  };

  const onShowOverlay = () => {
    setOverlay(!overlay);
  };

  const setVaccinationTileVisibility = async () => {
    let status = getVaccnationStatus();
    let isVaccEnabled = !isUndefinedOrNull(status);
    await setDashboardTilesVisibility(TILE_VACCIANTION, isVaccEnabled);
  };

  const setTestingTileVisibility = async flag => {
    await setDashboardTilesVisibility(TILE_TESTING, flag);
  };

  const setDashboardTilesVisibility = async (tileType, isVisible) => {
    let tilesList = JSON.parse(JSON.stringify(tilesArray));
    let idx = tilesList.findIndex(it => it.type == tileType);
    if (idx >= 0) {
      tilesList[idx].visibility = isVisible;
      let arr = [];
      for (let i = 0; i < tilesList.length; i++) {
        if (tilesList[i].visibility) {
          arr.push(tilesList[i]);
        }
      }
      let filterTilesArray = arr.concat(customTilesArray);
      setTilesArray(tilesList);
      setTilesArrayDisplay(filterTilesArray);
      setRefreshing(false);
    }
    Events.publish(
      'UPDATE_DRAWER_MENU_CUSTOM_TILES_COUNT',
      new Date().getTime() + '',
    );
  };
  // End Vaccinatation Tile visibility

  //Start Testing tile Visibility
  const loadCheckInData = async () => {
    let data = await checkInDataModel.getFormattedCheckInData();
    setCheckInData(data);
    let isTestingEnabled = data.subProfile[0].isTestSubmittionEnabled;

    setTestingTileVisibility(isTestingEnabled);
  };

  const disableTestingHistory = () => {
    let d = getDashboardSelectedRecord();
    if (d) {
      if (!d.latestTest) return true;
    }
    return false;
  };

  const getSelectedUserId = () => {
    if (!userDashboardData) return;

    let d = userDashboardData[0];
    if (d) {
      return d.appUserId;
    }
    return;
  };

  const getDashboardSelectedRecord = () => {
    if (userDashboardData) {
      let obj = userDashboardData[0];
      return obj;
    }
  };

  const displayEvaluatingDialog = async flag => {
    let netw = await isInternetAvailable();
    if (!netw) return;
    if (flag && showEvalutingDialog) return;
    if (!flag && !showEvalutingDialog) return;
    setShowEvalutingDialog(flag);
  };

  const goToVaccinationSubmitPage = async () => {
    let user = await getUserById(getSelectedUserId());
    let vacinationProtocol = await getVacinationProtocol(user);
    user.userId = user.appUserId;
    user.vaccineData = vacinationProtocol;
    props.navigation.navigate('VaccinationRecord', {
      user: user,
      onVaccinationRecordUpdate: onVaccinationRecordUpdate,
      onMedicalNotesSubmit: onMedicalNotesSubmit,
      routeName: 'checkIn',
    });
  };

  const goToTestingSubmitPage = async () => {
    let user = checkInData.subProfile[0];

    props.navigation.navigate('TestResultForm', {
      user: user,
      userId: user.userId,
      vaccinationRecords: [],
      onVaccinationRecordUpdate: onVaccinationRecordUpdate,
      onMedicalNotesSubmit: onMedicalNotesSubmit,
      onTestRecordSubmit: onTestRecordSubmit,
      routeName: 'CheckIn',
      navigatingFrom: 'CheckIn',
      existingTestRecord: undefined,
    });
  };

  const onPullToRefresh = async () => {
    setRefreshing(true);
    await fetchCustomTilesData();
    onScreenFocus();
    loadCheckInData();
  };

  const onVaccinationRecordUpdate = async () => {};
  const onMedicalNotesSubmit = () => {};
  const onTestRecordSubmit = () => {};

  return (
    <View style={{flex: 1}}>
      <HealthTilesPageView
        navigation={props.navigation}
        name={userName}
        tabType={I18n.t('menuItemLabel.' + 'menu_health_safety')}
        spinnerVisibility={false}
        selectedPickerIndex={0}
        setSelectedPickerIndex={() => {}}
        setBlueTick={() => {}}
        isVaccinationHistoryExist={() => {}}
        navigateToNotificationScreen={() => {
          props.navigation.navigate('NotificationListScreen');
        }}
        navigateToSettingScreen={() => {
          props.navigation.navigate('menuStack');
        }}
        tilesArrayDisplay={tilesArrayDisplay}
        onTileClick={(url, inAppBrowserType, title) => {
          props.navigation.navigate('TabComponentsWebView', {
            url: url,
            title: title,
          });
        }}
        goToLinks={() => goToLinks()}
        vaccinationStatus={getVaccnationStatus()}
        vaccinationStatusData={getVaccnationStatusData()}
        testingStatus={getTestingStatus()}
        testingStatusData={getTestingStatusData()}
        showBlueTickOnVaccination={blueTickOnVaccination}
        showBlueTickOnTesting={blueTickOnTesting}
        disableVacciantionOverlay={disableVacciantionOverlay}
        disableTestingHistory={disableTestingHistory()}
        showOverlay={overlay}
        onShowOverlay={() => {
          onShowOverlay();
        }}
        onNavigateToRecord={() => {
          props.navigation.navigate('VaccinationHistory', {});
        }}
        onNavigateToTestRecord={() => {
          global.testResult_user_appid = getSelectedUserId();
          props.navigation.navigate('TestStatusScreen', {
            testResultData: getDashboardSelectedRecord(),
          });
        }}
        goToVaccinationSubmitPage={() => {
          goToVaccinationSubmitPage();
        }}
        goToTestingSubmitPage={() => {
          goToTestingSubmitPage();
        }}
        showEvalutingDialog={showEvalutingDialog}
        refreshing={refreshing}
        onStartRefresh={() => {
          onPullToRefresh();
        }}
      />
    </View>
  );
};
const mapStateToProps = state => {
  return {
    data: state.WellBeingReducer,
  };
};
const mapDispatchToProps = {
  getWellBeingData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthScreen);
