//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet ,TouchableOpacity} from 'react-native';
import { colors } from '../../../styles/colors';
import { verticalScale2 ,scale2} from '../../../styles/Scaling';
import { textStyles } from '../../../styles/textFontStyles';
import I18n from '../../../utils/I18n';
const languagepath = 'dashboard.'

// create a component
const RecommendedLinkComponent = (props) => {

    return (
        <TouchableOpacity onPress={()=>{props.navigateToLinks()}} style={styles.container}>
            <Text style={[textStyles.InterMedium, { fontSize: verticalScale2(14) }]}>{I18n.t(languagepath+'recommendedLink')}</Text>
        </TouchableOpacity>
    );
};

// define your styles
const styles = StyleSheet.create({
    container: {
        height: verticalScale2(44),
        backgroundColor: colors.white,
        borderRadius: 10,
        shadowColor: 'rgb(0, 0, 0)',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 13,
        elevation: 3,
        paddingHorizontal: scale2(12),
        marginHorizontal : scale2(8),
        justifyContent: 'center',
        width : '95%'

    },
});

//make this component available to the app
export default RecommendedLinkComponent;
