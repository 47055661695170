import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Modal, TouchableOpacity } from "react-native";
import Events from '../router/events';
import { scale2 } from '../styles/Scaling';
import CustomBottomSheet from './CustomBottomSheet';
import ImageResizer from 'react-native-image-resizer';
import { showLog } from '../models/logModel';
// import ImagePicker from 'react-native-image-crop-picker';
import * as DataManager from '../repository/DataManager';
import { SP_CREDENTIALS } from '../repository/SharedPreferenceHelper';
import { getHeaders } from '../utils/AppUtils';
import { getBaseUrl } from './BaseComponent';
import axios from 'axios';
import { EVENT_PROFILE_IMAGE_REMOVE } from './ProfilePicHolder';
import { colors } from '../styles/colors';
import { uploadImage, uploadProfileImage } from '../models/ImageFileManager';

export const EVENT_SHOW_IMAGE_SELECTION_POP_UP_MODEL = 'EVENT_SHOW_IMAGE_SELECTION_POP_UP_MODEL';
export const EVENT_PROFILE_IMAGE_SELECTED = 'EVENT_PROFILE_IMAGE_SELECTED';
export const EVENT_PROFILE_IMAGE_UPLOADING = 'EVENT_PROFILE_IMAGE_UPLOADING';
export const EVENT_PROFILE_IMAGE_UPLOADING_FINISHED = 'EVENT_PROFILE_IMAGE_UPLOADING_FINISHED';

const BottomPopUpImageSelection = (props) => {

    const [visibility, setVisibility] = useState(false);
    const [removeImageOption, setRemoveImageOption] = useState(false);

    useEffect(() => {
        let eventHandle = Events.subscribe(
            EVENT_SHOW_IMAGE_SELECTION_POP_UP_MODEL,
            value => {
                showLog('EVENT_SHOW_IMAGE_SELECTION_POP_UP_MODEL value: ' + value);
                setRemoveImageOption(value);
                setVisibility(true);
            },
        );
        return () => eventHandle.remove();
    }, []);

    const onBottomSheetClose = () => {
        setVisibility(false);
    }

    const launchCameraa = async () => {
        try {
            // ImagePicker.openCamera({
            //     width: 400,
            //     height: 400,
            //     cropping: true,
            //     useFrontCamera:true
            // }).then(image => {
            //     resizeFile(image.path);
            // });
        } catch (err) {
            return;
        }
    };

    const launchLib = () => {
        try {
            // ImagePicker.openPicker({
            //     width: 400,
            //     height: 400,
            //     cropping: true,
            // }).then(image => {
            //     resizeFile(image.path);
            // });
        } catch (err) {
            return;
        }
    };

    const resizeFile = (uri) => {
        ImageResizer.createResizedImage(uri, 512, 512, 'JPEG', 80).then((response) => {
            let path = response.path;
            if (Platform.OS == 'ios') {
                path = response.uri;
            } else if (Platform.OS == 'android') {
                path = 'file://' + response.uri;
            }
            let source = { uri: path, fileName: response.name, isBase64: false, isFromWeb:false };
            onBottomSheetClose();
            uploadProfileImage(source)

        }).catch((err) => {
        });
    }

    const onRemoveClick = () => {
        Events.publish(EVENT_PROFILE_IMAGE_REMOVE, true);
        onBottomSheetClose();
    }

    if (visibility) {
        return (

            <View
                style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: colors.semiTransparentDarkerBgColor,
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute'
                }}>
                <CustomBottomSheet
                    showDeleteOption={removeImageOption}
                    bottomSheetBackgroundShow={false}
                    hideCameraOption={true}
                    takeFromCamera={(id) => {
                        launchCameraa();
                    }}
                    pickFromGallery={(id) => {
                        launchLib();
                    }}
                    removeImage={() => {
                        onRemoveClick()
                    }}
                    cancel={() => {
                        onBottomSheetClose()
                    }} />
            </View>
        );
    } else {
        return null;
    }

}
const styles = StyleSheet.create({
    bottomSheetContentStyle: {
        flex: 0.20,
        backgroundColor: '#fff',
        paddingTop: scale2(10),
        paddingBottom: scale2(10),
        borderTopLeftRadius: scale2(12),
        borderTopRightRadius: scale2(12)
    }
});
export default BottomPopUpImageSelection;