import React, { useEffect, useState } from 'react';
import { Modal, StyleSheet, TouchableOpacity, View, Text, Platform, Image } from 'react-native';
import { getAllUsers, getGlobalUserName } from '../models/usersModel';
import ProfilePicHolder from './ProfilePicHolder';
import I18n from '../utils/I18n';
import { PURPOSE } from '../config/Enums';
import { EVENT_PROFILE_NAME_UPDATED } from '../containers/ProfileScreen';
import Events from '../router/events';

const DrawerMenuHeader = props => {

    const [name, setName] = useState('');

    useEffect(() => {
        setData();
    }, []);

    useEffect(() => {
        let eventHandle = Events.subscribe(
            EVENT_PROFILE_NAME_UPDATED,
            data => {
                setData();
            },
        );
        return () => eventHandle.remove();
    }, []);


    const setData = async () => {
        let users = await getAllUsers(PURPOSE.Profile);
        let name =  users[0].name;
        setName(name);
    }

    return (
        <View style={{
            alignItems: 'center',
            flexDirection: 'row',
            paddingHorizontal: 22,
            paddingTop: 20,
            paddingBottom: 20,
        }}>
            {Platform.OS == 'web' ? (
                <TouchableOpacity
                    style={{
                        marginRight: 5,
                        position: 'absolute',
                        right: 14,
                        top: 12
                    }}
                    activeOpacity={0.8}
                    onPress={() => { props.navigation.closeDrawer() }}>
                    <Image
                        style={{
                            width: 16,
                            height: 16,

                        }}
                        source={require('../assets/images/ic_clear_light.png')}
                    />
                </TouchableOpacity>
            ) : null}

            <ProfilePicHolder
                size={34}
            />
            <View style={{
                marginLeft: 10
            }}>
                <Text style={styles.titleTextStyle}>
                    {I18n.t('menuItemLabel.' + 'label_hello')}
                </Text>
                <Text style={styles.subTitleTextStyle}>
                    {name}
                </Text>
            </View>
        </View>
    );
}
const styles = StyleSheet.create({
    titleTextStyle: {
        fontFamily:
            Platform.OS === 'web'
                ? 'Inter-SemiBold'
                : Platform.OS === 'android'
                    ? 'Inter-Medium'
                    : 'Inter-Medium',
        fontWeight: '500',
        fontSize: 13,
        color: '#0BF6F2'
    },
    subTitleTextStyle: {
        fontFamily:
            Platform.OS === 'web'
                ? 'Inter-SemiBold'
                : Platform.OS === 'android'
                    ? 'Inter-SemiBold'
                    : 'Inter-SemiBold',
        fontWeight: '600',
        fontSize: 18,
        color: '#fff'
    },
});
export default DrawerMenuHeader;