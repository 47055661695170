//import liraries
import React, {useState, useEffect} from 'react';
import { View, Text, StyleSheet ,TouchableOpacity} from 'react-native';
import PlainButton from '../components/molecules/Buttons/PlainButton';
import I18n from '../utils/I18n';
import RNRestart from 'react-native-restart';
import { Restart } from 'fiction-expo-restart';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { isMobile } from '../utils/AppUtils';
import PrimaryButton from '../components/molecules/Buttons/PrimaryButton';
import { globalStyles } from '../config/globalStyles';
import { GLOBAL } from '../../App';

// create a component
const LanguageSwitcher = () => {
    const [languages, setLanguages] = useState([
        {code: 'en', name: 'English', checked: false},
        { code: 'it', name: 'Itlaian', checked: false },
        {code: 'es', name: 'Spanish', checked: false },
        {code: 'fr', name: 'French', checked: false},
    ]);
    const [selectedLanguage, setSelectedLanguage] = useState();

    const renderLanguages = (item,index) => {
        return (
            <TouchableOpacity style={{height : 30,width : 100,backgroundColor : item.checked?'green':'transparent'}} onPress={() => {
                setSelectedLanguage(item.code)
                 var newData = [...languages];

                let data = newData.map((item, ind) => {
                    if (index == ind) {
                        item.checked = true;
                    } else {
                        item.checked = false
                    }
                    return item;
                })

                setLanguages(data)
            }}>
                <Text>{ item.name}</Text>
            </TouchableOpacity>
        )
    }
    return (
        <View style={styles.container}>
            <Text style={{fontWeight : 'bold' , fontSize : 20,marginBottom : 50}}>Select Language</Text>
            {
                languages.map((item,index) => {
                    return renderLanguages(item,index)
                })
            }
            <PrimaryButton
            style={globalStyles.actionButton}
            text={'Save'}
                onPress={() => {
                    alert("lang="+selectedLanguage)
                    AsyncStorage.setItem('language', selectedLanguage);
                    GLOBAL.APP_LANGUAGE = selectedLanguage;
                    if (isMobile()) {
                        RNRestart.Restart()
                    } else {
                        Restart();
                    }

            }}
          />
        </View>
    );
};

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      //  backgroundColor: '#2c3e50',
    },
});

//make this component available to the app
export default LanguageSwitcher;
