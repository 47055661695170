import React, {Component} from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Platform,
  SafeAreaView,
  ScrollView,
} from 'react-native';
import {colors} from '../../../styles/colors';
import { verticalScale2} from '../../../styles/Scaling';
import {textStyles} from '../../../styles/textFontStyles';
import BackButton from '../../../components/molecules/BackButton';
import Events from '../../../router/events';
import I18n from '../../../utils/I18n';
const MenuComponent = props => {
  const languagepath = 'profile.';
  return (
    <SafeAreaView style={styles.safeArea}>
      <BackButton
        style={{marginLeft: verticalScale2(0)}}
        onBackClick={() => {
          props.navigation.pop();
        }}
        isVisible={true}
        title={I18n.t(languagepath + 'menu')}
        buttonColor={colors.primary_blue}
      />
      <ScrollView
        contentContainerStyle={{flex: 1, paddingHorizontal: 5, paddingTop: 10}}>
        <TouchableOpacity
          style={{
            width: '75%',
            justifyContent: 'center',
            height: verticalScale2(78),
            marginHorizontal: verticalScale2(25),
            // backgroundColor: '#ff2'
          }}
          onPress={() => props.navigation.navigate('ProfileStack')}>
          <Text
            style={[
              textStyles.InterSemiBold,
              {
                fontSize: verticalScale2(16),
                color: colors.form_field_value_color,
                textAlign: 'left',
                // marginLeft: '6%',
              },
            ]}>
            {I18n.t(languagepath + 'profile')}
          </Text>
        </TouchableOpacity>

        <View
          style={{
            width: '100%',
            height: 2,
            backgroundColor: colors.seperator_color,
          }}
        />

        <TouchableOpacity
          style={{
            width: '75%',
            justifyContent: 'center',
            height: verticalScale2(78),
            marginHorizontal: verticalScale2(25),
            // backgroundColor: '#ff2'
          }}
          onPress={() => {
            props.navigation.navigate('SettingsScreen');
          }}>
          <Text
            style={[
              textStyles.InterSemiBold,
              {
                fontSize: verticalScale2(16),
                color: colors.form_field_value_color,
                textAlign: 'left',
                // marginLeft: '4%',
              },
            ]}>
            {I18n.t(languagepath + 'settings')}
          </Text>
        </TouchableOpacity>

        <View
          style={{
            width: '100%',
            height: 2,
            backgroundColor: colors.seperator_color,
          }}
        />

        <TouchableOpacity
          style={{
            width: '50%',
            justifyContent: 'center',
            height: verticalScale2(78),
            marginHorizontal: verticalScale2(25),
            //backgroundColor: '#ff2'
          }}
          onPress={() => {
            Events.publish('EVENT_LOGOUT');
          }}>
          <Text
            style={[
              textStyles.InterSemiBold,
              {
                fontSize: verticalScale2(16),
                color: colors.form_field_value_color,
                textAlign: 'left',
                //marginLeft: '4%',
              },
            ]}>
            {I18n.t(languagepath + 'logout')}
          </Text>
        </TouchableOpacity>
      </ScrollView>
    </SafeAreaView>
  );
};

export default MenuComponent;
const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: 'white',
  },
  dropdownStyle: {
    width: '38%',
    height: Platform.OS == 'android' ? 100 : 110,
    backgroundColor: 'white',
    borderRadius: 8,
    elevation: 5,
    // overflow: 'hidden',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.12,
    shadowRadius: 15,
    marginTop: Platform.OS == 'ios' ? 8 : 5,
    paddingHorizontal: 5,
    paddingVertical: 7,
  },
});
