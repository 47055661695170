import * as Actions from '../actions/actionTypes';
import {showLog} from '../../models/logModel';
const initialState = {
  loading: false,
  responseData: '',
  responseHeader: ',',
  error: '',
};

export default function WellBeingReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_WELLBEING_START:
      return {...state, loading: true};
    case Actions.GET_WELLBEING_SUCCESS:
      return {
        ...state,
        loading: false,
        responseData: action.payload.data,
        responseHeader: action.payload.headers,
      };
    case Actions.GET_WELLBEING_FAIL:
      return {
        ...state,
        loading: false,
        error: 'An error occoured. Please try again.',
      };
    default:
      return state;
  }
}

export function getWellBeingData(header, appUserId) {
  return {
    type: Actions.GET_WELLBEING_START,
    payload: {
      request: {
        url: 'employee-wellness-app?appUserId=' + appUserId,
        method: 'GET',
        headers: header,
      },
    },
  };
}
