import React, {useEffect, useState} from 'react';
import {StyleSheet, View, Text, Platform} from 'react-native';
import {
  getBenefitsCustomTiles,
  getCultureCustomTiles,
  getEngagementCustomTiles,
  getHealthCustomTiles,
  getWellBeingCustomTiles,
} from '../repository/CustomTilesDataHelper';
import Events from '../router/events';
import {isUndefinedOrNull} from '../config/Strings/StringUtil';
import * as DashboardModel from '../models/dashboardModel';
import * as checkInDataModel from '../models/checkInDataModel';
import {getUnreadNotificationCount} from '../pushNotifications/NotificationModel';
import {showLog} from '../models/logModel';
import { colors } from '../styles/colors';

const DrawerMenuBadge = props => {
  const [badgeCountHome, setBadgeCountHome] = useState(0);
  const [badgeCountWellbieng, setBadgeCountWellbeing] = useState(0);
  const [badgeCountHealth, setBadgeCountHealth] = useState(0);
  const [badgeCountEngagement, setBadgeCountEngagement] = useState(0);
  const [badgeCountCulture, setBadgeCountCulture] = useState(0);
  const [badgeCountBenefits, setBadgeCountBenefits] = useState(0);
  const [badgeCountNotification, setBadgeCountNotification] = useState(0);
  showLog('DrawerMenuBadge props.activeRouteIndex: ' + props.activeRouteIndex);
  useEffect(() => {
    loadBadgeCount();
  }, [props.routeKey]);

  //Home screen tiles count update
  useEffect(() => {
    let eventHandle = Events.subscribe(
      'UPDATE_DRAWER_MENU_HOME_TILES_COUNT',
      data => {
        if (data) {
          if (props.routeKey == 'Home') {
            // setBadgeCount(parseInt(data));
            setBadgeCountHome(parseInt(data));
          }
        }
      },
    );
    return () => eventHandle.remove();
  }, []);

  //Wellbeing,Engagement,Culture,Benefits screen tiles count update
  useEffect(() => {
    let eventHandle = Events.subscribe(
      'UPDATE_DRAWER_MENU_CUSTOM_TILES_COUNT',
      data => {
        loadBadgeCount();
      },
    );
    return () => eventHandle.remove();
  }, []);

  //Notification counts update
  useEffect(() => {
    let eventHandle = Events.subscribe(
      'UPDATE_DRAWER_MENU_NOTIFICATION_COUNT',
      data => {
        showLog(
          'Fri4Nov2 UPDATE_DRAWER_MENU_NOTIFICATION_COUNT DrawerMenuBadge props.routeKey: ' +
            props.routeKey,
        );
        if (true || props.routeKey == 'Notifications') {
          setNotificationCount();
        }
      },
    );
    return () => eventHandle.remove();
  }, []);

  const loadBadgeCount = () => {
    switch (props.routeKey) {
      case 'Wellbeing':
        loadWellbeingTilesCount();
        break;
      case 'Health':
        loadHealthTilesCount();
        break;
      case 'Engagement':
        engagementTilesCount();
        break;
      case 'Culture':
        cultureTilesCount();
        break;
      case 'Benefits':
        benefitsTilesCount();
        break;
      case 'Notifications':
        setNotificationCount();
        break;
      default:
        console.log('default');
        break;
    }
  };

  const setNotificationCount = async () => {
    showLog('Fri4Nov2 setNotificationCount');
    let count = await getUnreadNotificationCount();
    showLog('Fri4Nov2 setNotificationCount count: ' + count);
    setBadgeCountNotification(count);
  };

  const isBadgeAllowed = label => {
    showLog('Fri4Nov2 isBadgeAllowed label: ' + label);
    if (label == 'Settings' || label == 'Profile' || label == 'Logout')
      return false;
    if (label == 'Home' && badgeCountHome == 0) return false;
    if (label == 'Wellbeing' && badgeCountWellbieng == 0) return false;
    if (label == 'Health' && badgeCountHealth == 0) return false;
    if (label == 'Engagement' && badgeCountEngagement == 0) return false;
    if (label == 'Culture' && badgeCountCulture == 0) return false;
    if (label == 'Benefits' && badgeCountBenefits == 0) return false;
    if (label == 'Notifications' && badgeCountNotification == 0) return false;
    return true;
  };

  const getBadgeBackgroundColor = label => {
    if (label == 'Notifications') return '#0BF6F2';
    return '#09133D';
  };

  const getBadgeLabelColor = label => {
    if (label == 'Notifications') return '#09133D';
    return '#fff';
  };

  const loadWellbeingTilesCount = async () => {
    let jsonObj = await getWellBeingCustomTiles();
    // if (jsonObj && jsonObj.length > 0) setBadgeCount(jsonObj.length);
    // else setBadgeCount(0);
    if (jsonObj && jsonObj.length > 0) setBadgeCountWellbeing(jsonObj.length);
    else setBadgeCountWellbeing(0);
  };

  const loadHealthTilesCount = async () => {
    let isVaccEnabled = false;
    let isTestingEnabled = false;
    let customTilesCount = 0;

    let userDashboardData = await DashboardModel.getDashboardData();
    if (userDashboardData && userDashboardData.length > 0) {
      let obj = userDashboardData[0];
      if (obj && obj.immunization_dashboard_status) {
        let status = obj.immunization_dashboard_status.app_dashboard_status;
        isVaccEnabled = !isUndefinedOrNull(status);
      }
    }

    let data = await checkInDataModel.getFormattedCheckInData();
    isTestingEnabled = data.subProfile[0].isTestSubmittionEnabled;

    let jsonObj = await getHealthCustomTiles();

    if (jsonObj && jsonObj.length > 0) customTilesCount = jsonObj.length;
    if (isVaccEnabled) customTilesCount = customTilesCount + 1;
    if (isTestingEnabled) customTilesCount = customTilesCount + 1;

    // setBadgeCount(customTilesCount);
    showLog(
      'getBadgeCount loadHealthTilesCount customTilesCount: ' +
        customTilesCount,
    );
    setBadgeCountHealth(customTilesCount);
  };

  const engagementTilesCount = async () => {
    let jsonObj = await getEngagementCustomTiles();
    // if (jsonObj && jsonObj.length > 0) setBadgeCount(jsonObj.length);
    // else setBadgeCount(0);
    if (jsonObj && jsonObj.length > 0) setBadgeCountEngagement(jsonObj.length);
    else setBadgeCountEngagement(0);
  };

  const cultureTilesCount = async () => {
    let jsonObj = await getCultureCustomTiles();
    // if (jsonObj && jsonObj.length > 0) setBadgeCount(jsonObj.length);
    // else setBadgeCount(0);
    if (jsonObj && jsonObj.length > 0) setBadgeCountCulture(jsonObj.length);
    else setBadgeCountCulture(0);
  };

  const benefitsTilesCount = async () => {
    let jsonObj = await getBenefitsCustomTiles();
    // if (jsonObj && jsonObj.length > 0) setBadgeCount(jsonObj.length);
    // else setBadgeCount(0);
    if (jsonObj && jsonObj.length > 0) setBadgeCountBenefits(jsonObj.length);
    else setBadgeCountBenefits(0);
  };

  const getBadgeCount = () => {
    showLog('Fri4Nov2 getBadgeCount XXX');
    if (props.routeKey == 'Home') {
      showLog('getBadgeCount Home : ' + badgeCountHome);
      return badgeCountHome;
    }
    if (props.routeKey == 'Wellbeing') {
      showLog('getBadgeCount Wellbeing : ' + badgeCountWellbieng);
      return badgeCountWellbieng;
    }
    if (props.routeKey == 'Health') {
      showLog('getBadgeCount Health : ' + badgeCountHealth);
      return badgeCountHealth;
    }
    if (props.routeKey == 'Engagement') {
      showLog('getBadgeCount Engagement : ' + badgeCountEngagement);
      return badgeCountEngagement;
    }
    if (props.routeKey == 'Culture') {
      showLog('getBadgeCount Culture : ' + badgeCountCulture);
      return badgeCountCulture;
    }
    if (props.routeKey == 'Benefits') {
      showLog('getBadgeCount Benefits : ' + badgeCountBenefits);
      return badgeCountBenefits;
    }
    if (props.routeKey == 'Notifications') {
      showLog(
        'Fri4Nov2 getBadgeCount Notifications : ' + badgeCountNotification,
      );
      return badgeCountNotification;
    }
  };

  if (isBadgeAllowed(props.routeKey)) {
    return (
      <View
        style={{
          width: 20,
          height: 20,
          backgroundColor: getBadgeBackgroundColor(props.routeKey),
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 4,
          alignSelf: 'center',
        }}>
        <Text
          style={[
            styles.textStyle,
            {color: getBadgeLabelColor(props.routeKey)},
          ]}>
          {getBadgeCount()}
        </Text>
        <Text style={[styles.textStyle, {width: 0, height: 0, color:colors.drawerMenuColor}]}>
          {badgeCountNotification}
        </Text>
      </View>
    );
  } else {
    showLog('getBadgeCount else XXX');
    return null;
  }
};
const styles = StyleSheet.create({
  textStyle: {
    fontFamily:
      Platform.OS === 'web'
        ? 'Inter-SemiBold'
        : Platform.OS === 'android'
        ? 'Inter-Bold'
        : 'Inter-Bold',
    fontWeight: '700',
    fontSize: 12,
    color: '#fff',
  },
});
export default DrawerMenuBadge;
